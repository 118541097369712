import { models } from "powerbi-client"
import { fetchJson, fetchBlob, fetchOk, addVerificationToken, fetchString, fetchFileBlob, fetchNumber } from "./datacraft-util"
import { links } from "./links"
import { getSecurityAmender, ISecurityAmender } from "./modules/helpers/authentication-helper"
import { Operation } from "fast-json-patch"
import { raiseFetchResponseError } from "./modules/helpers/response-helpers"
import { DataTableConfiguration, Export, ExportColumn } from "./exports/exportTypes"

export interface IReportInfoResponse {
  displayName:string,
  hasDocumentation: boolean,
  displayBookmarks: boolean,
  canDownload: boolean
  reportKey: number,
  allowsFeedback: boolean,
  clientGroup:string,
  exports: IReportExportResponse[]
  embedSettings: IEmbedSettings
}
export interface IReportExportResponse {
  exportName: string
  pageName: string
  containerName: string
  exportKey: number
}

export interface IReportNews {
  html: string;
}

export type MarkdownModelIdentity = { type:"subscriber", subscriberKey:number} | { type:"messaging", messageName:string} 

export interface IEmbedSettings {
  navContentPaneEnabled?: boolean
  // todo caps!!!???
  FilterPaneEnabled?: boolean
  layoutType?:models.LayoutType
}

export const reportInfo = async (reportKey: number) => await fetchJson<IReportInfoResponse>(`/api/report/${reportKey}/info`);

export interface IEmbedCodeResponse {
  embedToken: string,
  url: string,
  reportId: string,
  expiration: string,
  powerBiType: "Report"|"Dashboard"
  datasets:{[Key: string]: string}
  
}


export interface IMenuQueryView {
  displayInbox:boolean
  displayBadges:boolean
  search:boolean
  lists:boolean
  isFacilityManager:boolean
  features:string[]
  items: INavItem[]
  subscribers: { subscriberKey: number, name: string }[]
}

export interface INavItem {
  title: string,
  icon: string,
  key: number,
  facilityReference?: string
  kind: "Link" | "Report"
  url: string,
  subscriberKey?:number
  items?: INavItem[]
}



export type PackFileItem = { name: string, packFileId:number }


export type PackSummary = {  packId: number;  name: string; };
export type PackReaderSummary = PackSummary & { isRead:boolean }
export type PackEditorSummary = PackSummary & { isPublished:boolean, groupKey?:number, groupName?:string };
export type PackEditorDetailPractice = {
  reference: string,
  practiceKey:number,
  name: string
  files: PackFileItem[]
  users:{userKey:number, username:string }[]
}
export type PackEditorDetail = PackEditorSummary & {
  folder:string
  everyoneFiles: PackFileItem[]
  practices: PackEditorDetailPractice[],
  rawMarkdown:string
};

export type PackStatsModel = { practiceAccess:PackStatsPracticeModel[], userAccess:{userKey:number, username:string}[],  fileAccess:{ practiceName:string, filename:string,downloadCount:number, usernames:string[]}[], events:{date:string,username:string, eventName:string,description:string}[]}
export type PackStatsPracticeModel = {practiceName:string, opened:boolean}

export type PackUserPractice =  { name:string, files:PackUserPracticeFile[]}
export type PackUserPracticeFile =  { packFileId:number, name:string}
export type PackReaderDetail = PackSummary & {
  packId:number
  name:string
  files:PackUserPracticeFile[]
  practices:PackUserPractice[]
  htmlContent:string

}


export type AuditableUsersResult = {
  practiceId:number,
  practiceName:string,
  users:AuditableUserResult[]
}

export type AuditableUserResult = {
    name:string,
    targetUserKey:Number
}

export type FacilityModel = {
  reference:string,
  name:string
}

export type UserModel = {
  username:string
  userKey:number,
  email:string
}

export type FacilityUserDetailModel = {
  userKey:number,
  username:string
  email:string,
  facilityReference:string
  selectedRoles:number[],
  availableRoles:AvailableRolesModel[]
}

export type AvailableRolesModel = {
  groupKey:number
  name:string,
  parentName:string
  explanation: string
}


export type FacilityKeys ={ [name: string]: string }


type BadgeResponse= {badgeName:string, description:string, icon:string}


export type DataTableId = string



export type ListModel = { 
  name:string 
  configuration:DataTableConfiguration,
  columns:ExportColumn[],
  data:any[]
}

/*
export const downloadReport = async (reportKey: number, exportId: string): Promise<Blob> => await fetchBlob(`/api/powerbi/downloadReport/${reportKey}`, addVerificationToken({
  method: "POST",
  headers: {
    'Accept': 'application/json; charset=utf-8',
    'Content-Type': 'application/json;charset=UTF-8'
  },
  body: JSON.stringify({ exportId })
}));
*/
export const updateCategory = async (key: number, description: string, icon: string) => await fetchOk(`/api/category/${key}`, addVerificationToken({
  method: "PUT", headers: {
    'Accept': 'application/json; charset=utf-8',
    'Content-Type': 'application/json;charset=UTF-8'
  },
  body: JSON.stringify({ description, icon })
}));


export const deleteCategory = async (key: number) => await fetchOk(`/api/category/${key}`, addVerificationToken({
  method: "DELETE", headers: {
    'Accept': 'application/json; charset=utf-8',
    'Content-Type': 'application/json;charset=UTF-8'
  },
}));

export const createCategory = async (description: string, icon: string): Promise<{ key: number }> => await fetchJson(`/api/category/`, addVerificationToken({
  method: "POST", headers: {
    'Accept': 'application/json; charset=utf-8',
    'Content-Type': 'application/json;charset=UTF-8'
  },
  body: JSON.stringify({ description, icon })
}));



export const updateGroupUser = async (groupUserKey: number, drillDown: Boolean) => await fetchOk(`/api/groupUser/${groupUserKey}`, addVerificationToken({
  method: "PUT", headers: {
    'Accept': 'application/json; charset=utf-8',
    'Content-Type': 'application/json;charset=UTF-8'
  },
  body: JSON.stringify({ drillDown })
}));

const securityAmender:ISecurityAmender = getSecurityAmender();
export const services = {
  navigation:{
    menu: async () => await fetchJson<IMenuQueryView>('/api/navigation/menu', await securityAmender.AppendSecurityToRequest({}))
  },
  sms:{
    messageTemplates:async () => await fetchJson<{ template:string }[]>(`/api/sms/message-templates`, await securityAmender.AppendSecurityToRequest({})),
    send:async(payload:{ messages:{ message:string, phoneNumber:string, facility:string }[]}) => await fetchOk(`/api/sms/send-sms`, await postBody(payload)),
  },
  tables:{
    list:async () => await fetchJson<{ id:DataTableId, name:string, createdAt:string }[]>(`/api/data-tables/`, await securityAmender.AppendSecurityToRequest({})),
    create:async(name:string,configuration:DataTableConfiguration, columns: ExportColumn[], data:any[])=> await fetchJson<{id:DataTableId}>("/api/data-tables/create", await postBody({name, configuration, columns, data})),
    get:async(dataTableId:DataTableId) => await fetchJson<ListModel>(`/api/data-tables/${dataTableId}`, await securityAmender.AppendSecurityToRequest({})),
    update:async(dataTableId:DataTableId, data:any[] ) => await fetchOk(`/api/data-tables/${dataTableId}`, await postBody({ data })),
    delete:async(dataTableId:DataTableId) => await fetchOk(`/api/data-tables/${dataTableId}`, await deleteBody({}))
  },
  dashboard:{
    landingContent: async () => await fetchString('/LandingContent', await securityAmender.AppendSecurityToRequest({}))
  },
  report: {
    info: async (reportKey: number) => await fetchJson<IReportInfoResponse>(`/api/report/${reportKey}/info`, await securityAmender.AppendSecurityToRequest({})),
    news: async() => await fetchJson<IReportNews[]>('/api/report-news/list', await securityAmender.AppendSecurityToRequest({})),
    documentationContent:async (reportKey: number) => await fetchJson<{content:string}>(`/api/report/${reportKey}/documentation`, await securityAmender.AppendSecurityToRequest({})),
    sendFeedback: async (reportKey: number, message: string) => await fetchOk(`/api/report/${reportKey}/send-feedback`, await postBody(message)),
    embedToken: async (reportKey: number, username: string, facilityReference:string, isRefresh:boolean) => {  
      const userPart = username ? `&username=${username}` :"";
      const facilityPart = facilityReference ? `&facility=${facilityReference}`: "";
      return fetchJson<IEmbedCodeResponse>(`/api/powerbi/embedcode/${reportKey}/?isRefresh=${isRefresh}${userPart}${facilityPart}`,await securityAmender.AppendSecurityToRequest({}))
    },
    exports:{
      detail:async (reportKey:number, exportKey:number, facilityReference?:string) => {
        let facilityReferencePart = "";
        if(facilityReference)
        {
          facilityReferencePart = `?facility=${facilityReference}`;
        }
       return await fetchJson<{ decryptionKeys: FacilityKeys, export:Export}>(`/api/export/report/${reportKey}/export/${exportKey}/detail${facilityReferencePart}`, await securityAmender.AppendSecurityToRequest({}))
      },
      update:async (reportKey:number, exportKey:number, exportItem:Export) => await fetchOk(`/api/export/report/${reportKey}/export/${exportKey}/update-export2`, await postBody(exportItem)),
      create:async (reportKey:number, exportItem:Export) => await fetchNumber(`/api/export/report/${reportKey}/create-export2`, await postBody(exportItem))
    },
    download:{
      requestDownload : async (reportKey: number, state: string, pageName: string, facilityReference?:string, username?:string ): Promise<{ exportId: string }> => await fetchJson(`/api/powerbi/requestReportDownload/${reportKey}`, await postBody({ state, pageName, username, facilityReference })),
      downloadStatus: async (reportKey: number, exportId: string): Promise<{ state: " Running" | "Succeeded" | "Failed", complete: boolean, percentComplete: number }> => await fetchJson(`/api/powerbi/reportDownloadStatus/${reportKey}`, await postBody({ exportId })),
      retrieveDownload: async (reportKey: number, exportId: string): Promise<Blob> => await fetchBlob(`/api/powerbi/downloadReport/${reportKey}`, await postBody({ exportId }))
    }
  },
  facilities:{
    auditableUsers:async() => await fetchJson<AuditableUsersResult>(`/api/facilities/auditable-users`, await securityAmender.AppendSecurityToRequest({})),
    userDetail:async(facilityReference:string, userKey:number) => await fetchJson<FacilityUserDetailModel>(`/api/facilities/${facilityReference}/users/${userKey}`, await securityAmender.AppendSecurityToRequest({})).catch(raiseFetchResponseError),
    createFacilityUser: async(facilityReference:string, email:string, firstName:string, lastName:string) => await fetchJson<{ userKey:number }>(`/api/facilities/${facilityReference}/users/create`, await postBody({email,firstName,lastName})).catch(raiseFetchResponseError),
    updateFacilityUser: async(facilityReference:string, userKey:number, roleGroups:string[]) => await fetchOk(`/api/facilities/${facilityReference}/users/${userKey}/update`, await postBody({ roleGroups:roleGroups})).catch(raiseFetchResponseError),
    sendWelcome: async(facilityReference:string, userKey:number) => await fetchOk(`/api/facilities/${facilityReference}/users/${userKey}/send-welcome`, await postBody({})).catch(raiseFetchResponseError),
    removeUserFromFacility:  async(facilityReference:string, userKey:number) => await fetchOk(`/api/facilities/${facilityReference}/users/${userKey}`, await deleteBody({})).catch(raiseFetchResponseError),
    manageableFacilities: async (): Promise<FacilityModel[]> => await fetchJson<FacilityModel[]>(`/api/facilities/manageable-facilities`, await securityAmender.AppendSecurityToRequest({})).catch(raiseFetchResponseError),
    manageableUsers:async(facilityReference:string) => await fetchJson<UserModel[]>(`/api/facilities/${facilityReference}/manage/users`, await securityAmender.AppendSecurityToRequest({})).catch(raiseFetchResponseError),
  },
  management: {
    workspaces: async (): Promise<{ name: string, workspaceKey: number }[]> => await fetchJson(`/api/management/workspace`, {
      method: "GET", headers: {
        'Accept': 'application/json; charset=utf-8',
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }),
    workspace: {
      powerBiReports: async (powerBiGroupKey: number): Promise<[{ name: string, id: string }]> => await fetchJson(`/api/management/workspace/${powerBiGroupKey}/powerbi-reports`, {
        method: "GET", headers: {
          'Accept': 'application/json; charset=utf-8',
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }),

    },
    markdown:{
      preview: async (markdown:string) => await fetchJson<{markup:string}>(links.markdown.preview(), await postBody({ markdown })),
      publish:async (entity:MarkdownModelIdentity, markdown:string) => {
        return await fetchOk(`/api/markdown/publish-content`, await postBody({ entity:entity, markdown }))
      },
      saveVersion:async (entity:MarkdownModelIdentity, markdown:string) => {
        return await fetchOk(`/api/markdown/save-version`, await postBody({ entity:entity, markdown }))
      },
      published:async (entity:MarkdownModelIdentity) => {
        return await fetchJson<{markdown:string}>(`/api/markdown/published-markdown`, await postBody(entity))
      },
    },
    listSubscribers: async (): Promise<{ subscriberKey: number, name: string }[]> => await fetchJson(`/api/management/subscribers/list`),
    createReport: async (subscriberKey: number, name: string, workspaceKey: number, reportId: string): Promise<number> => await fetchJson(`/api/management/${subscriberKey}/create-report/`,
      addVerificationToken({
        method: "POST",
        headers: {
          'Accept': 'application/json; charset=utf-8',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify({ name, workspaceKey, reportId })
      })
    ),
    exports: {
      delete: async (exportKey: number): Promise<void> => await fetchOk(`/api/export/${exportKey}`, addVerificationToken({
        method: "DELETE", headers: {
          'Accept': 'application/json; charset=utf-8',
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }))
    }
  },
  subscribers: {

    createReport: async (subscriberKey: number, data: { name: string, reportId: string, enableBookmarks: boolean, enableDownload: boolean, categories: number[] }): Promise<number> => await fetchJson(`/api/subscribers/${subscriberKey}/create-report/`,
      addVerificationToken({
        method: "POST",
        headers: {
          'Accept': 'application/json; charset=utf-8',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(data)
      })
    ),

    listPowerBiReports: async (subscriberKey: number): Promise<{ id: string, name: string, powerBiType:"Report"|"Dashboard" }[]> => await fetchJson(`/api/subscribers/${subscriberKey}/powerbi-reports`),
    requestPermissionPublish: async():Promise<void> => await fetchOk("/api/manage-subscriber/request-permission-publish", await postBody({}))
  },
  categories: {
    list: async (): Promise<{ key: number, name: string, icon: string }[]> => await fetchJson(`/api/category/`)
  },
  users: {
    ping: async (): Promise<void> => await fetchOk(`/api/user/ping`),
    sendFeedback:async (url:string, message:string, reportKey?:number) => await fetchOk(`/api/user/send-feedback`, await postBody({ url, message,reportKey })),
    colleagueAuditUser:async (targetUser:Number, facilityKey:number,  isCurrentEmployee:boolean) => await fetchOk(`/api/user/${targetUser}/practice/${facilityKey}/employedstatus`, await postBody(isCurrentEmployee))
  },
  currentUser:{
    facilities: async (): Promise<FacilityModel[]> => await fetchJson(`/api/current-user/facilities`,await securityAmender.AppendSecurityToRequest({})),
    colleagueAuditUser:async (targetUser:Number, facilityKey:string,  isCurrentEmployee:boolean) => await fetchOk(`/api/current-user/facilities/employment/${facilityKey}/${targetUser}`, await postBody(isCurrentEmployee))
    
  },
  badge:{
    current: async (): Promise<{ recent:BadgeResponse[], awarded:BadgeResponse[],available: BadgeResponse[] }> => await fetchJson(`/api/badge/current`,await securityAmender.AppendSecurityToRequest({}))
  },
  events:{
    raise:async(action:"TeamsOpened"|"BadgesOpened"|"TutorialsOpened"): Promise<void> => await fetchOk(`/api/events`, await postBody({actionType:action}))
  },
  groups:{
    list: async (subscriberKey:number, userGroupsOnly?:boolean): Promise<{name:string,groupKey:number}[]> => { 
      let userPart = "";
      if(userGroupsOnly != null)
      {
        userPart +=`&isUserGroup=${userGroupsOnly}`;
      }
      return await fetchJson(`/api/group/list?subscriberKey=${subscriberKey}${userPart}`,await securityAmender.AppendSecurityToRequest({}))
    },
  },
  packs:{
    list: async (): Promise<PackReaderSummary[]> => await fetchJson(`/api/pack/list`,await securityAmender.AppendSecurityToRequest({})),
    packUserDetail: async (packId:number) =>{
      return await fetchJson<PackReaderDetail>(`/api/pack/${packId}/user`, await securityAmender.AppendSecurityToRequest({}))
    },
    download: async(packFileId:number)  => {
      return await fetchFileBlob(`/api/packs/files/${packFileId}/download`,await securityAmender.AppendSecurityToRequest({}))
      //return await fetchFileBlob(`/api/pack/pack-file/${packFileId}/download`,await securityAmender.AppendSecurityToRequest({}))
    },

    editor:{
      pack:(packId:number) => ({
        delete: async () =>  await fetchOk(`/api/pack/editor/${packId}`, await deleteBody({})),
        edit: async (pack:PackEditorDetail) =>await fetchJson<PackEditorDetail>(`/api/pack/editor/update-pack`, await putBody(pack)),
        patch: async (operations:Operation[]) =>await fetchOk(`/api/pack/editor/patch-pack-summary/${packId}`, await postBody(operations)),
        detail: async () => await fetchJson<PackEditorDetail>(`/api/pack/editor/${packId}/detail`, {}),
        stats: async() => await fetchJson<PackStatsModel>(`/api/pack/editor/${packId}/stats`, await securityAmender.AppendSecurityToRequest({})),
        upload: async (formData:FormData) => {
          return await fetchOk(`/api/pack/editor/${packId}/upload`, await securityAmender.AppendSecurityToRequest({
                method: "POST",
                body: formData
              }));
        }
      }),
      createPack: async (subscriberKey:number, name:string, groupKey?:number) =>{
        return await fetchJson<PackEditorDetail>(`/api/pack/editor/create-pack`, await postBody({subscriberKey, name,groupKey}))
      },
      list: async (subscriberKey:number) =>{
        return await fetchJson<PackEditorSummary[]>(`/api/pack/editor/list/${subscriberKey}`, {})
      },
      deletePackFile: async(packFileId:number)  => {
        return await fetchOk(`/api/pack/editor/pack-file/${packFileId}`,await deleteBody({}))
      },
      sendTestEmail: async(packId:number, subject:string, body: string) =>{
        return await fetchOk(`/api/pack/editor/${packId}/test-email`,await postBody({ subject, body }))
      },
      sendEmail: async(packId:number, subject:string, body: string) =>{
        return await fetchOk(`/api/pack/editor/${packId}/send-email`,await postBody({ subject, body }))
      } 
    }
  }

}


const deleteBody = (data: any) => securityAmender.AppendSecurityToRequest({
  method: "DELETE",
  headers: {
    'Accept': 'application/json; charset=utf-8',
    'Content-Type': 'application/json;charset=UTF-8'
  },
  body: JSON.stringify(data)
});


const postBody = (data: any) => securityAmender.AppendSecurityToRequest({
  method: "POST",
  headers: {
    'Accept': 'application/json; charset=utf-8',
    'Content-Type': 'application/json;charset=UTF-8'
  },
  body: JSON.stringify(data)
});

const putBody = (data: any) => securityAmender.AppendSecurityToRequest({
  method: "PUT",
  headers: {
    'Accept': 'application/json; charset=utf-8',
    'Content-Type': 'application/json;charset=UTF-8'
  },
  body: JSON.stringify(data)
});

/*
// currently doesn't work... not sure why
const patchJSONBody = (operations:Operation[]) => securityAmender.AppendSecurityToRequest({
  method: "PATCH",
  headers: {
    'Accept': 'application/json; charset=utf-8',
    'Content-Type': 'application/json;charset=UTF-8'
  },
  body: JSON.stringify(operations)
});
*/
